import React from "react";
import classNames from 'classnames';

import s from './DetailComponent.module.scss';

interface PropsType {
    /** currently displayed node */
    detailsNode: any;
    /** function to allow parent to react to collapse change */
    collapsePropertyUpdated: () => void;
}

/**
 * Component rendering node side view
 */
export default class DetailComponent extends React.Component<PropsType, {}> {
    render() {
        if (!this.props.detailsNode) {
            return null;
        }

        return <div className={s.detailComponent}>
            <span className={s.Title}>{this.getNodeTitle()}</span>
            <div className={s.Body}>
                {Object.entries(this.props.detailsNode.planNodeProperties).map(([key, value]) =>
                    <React.Fragment key={key}>
                        <div className={s.ItemLabel}>
                            {key.split(/(?=[A-Z])/).join('\u200B')}:
                        </div>
                        <div className={s.ItemValue}>
                            {JSON.stringify(value, undefined, '\u200B')}
                        </div>
                    </React.Fragment>
                )}
                <div className={s.Buttons}>
                    <div
                        className={classNames(s.collapse, {[s.disabled]: !this.props.detailsNode.hasChildren})}
                        onClick={this.collapseButtonClicked}>{this.props.detailsNode.collapsed ? 'Expand Subtree' : 'Collapse Subtree'}
                    </div>
                </div>
            </div>
        </div>;
    }

    /**
     * return formatted node title
     */
    private getNodeTitle: () => string = () => {
        let data = this.props.detailsNode.planNodeProperties;
        if (data.type) {
            return (data.operator + ': ' + data.type).toUpperCase();
        }
        return data.operator.toUpperCase();
    };

    /**
     * toggle collapse property, call function property
     * {@see PropsType#collapsePropertyUpdated}
     */
    private collapseButtonClicked: () => void = () => {
        this.props.detailsNode.collapsed = !this.props.detailsNode.collapsed;
        this.forceUpdate();
        this.props.collapsePropertyUpdated();
    };
}
