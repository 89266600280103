import Loadable from 'react-loadable';
import * as React from 'react';
import { RectangleWaveSpinner } from '../../design-elements/Spinners';

import s from './ChartEditorLoader.module.scss';

function loadingSpinner() {
    return (
        <div className={s.ChartEditorLoader}>
            <RectangleWaveSpinner color="rgb(62, 61, 50)"  />
        </div>
    );
}

const ChartEditorLoadable = Loadable({
    loader: () => import('./ChartEditor'),
    loading: loadingSpinner
});

export default class ChartEditorLoader extends React.Component {
    public render() {
        return <ChartEditorLoadable />;
    }
}
