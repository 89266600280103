import { QueryDefinitions } from '../store';
import * as tpch from "./tpch";
import * as tpcds from "./tpcds";
import * as ssb from "./ssb";
import * as job from "./job";
import * as ldbc from "./ldbc";
import * as uni from "./uni";
import * as pizza from "./pizza";

export const queries: QueryDefinitions = {
    "pizza": pizza.queries,
    "tpch": tpch.queries,
    "tpcds": tpcds.queries,
    "ssb": ssb.queries,
    "job": job.queries,
    "ldbc": ldbc.queries,
    "uni": uni.queries,
}
