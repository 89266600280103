import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import { QueryDefinitions, QuerySet, Query } from '../../store';
import * as Store from '../../store';
import { SplitPane } from 'react-collapse-pane';
import ReactTable from 'react-table';
import ChartEditorLoader from './chart-editor/ChartEditorLoader';
import SQLEditorLoader from './sql-editor/SQLEditorLoader';
import { IAppContext, withAppContext } from '../../AppContext';
import { formatDurationMS } from '../../utils/Time';
import classNames from 'classnames';
import {
    InsertChartIcon,
    PlayIcon,
    PlanIcon,
    StopIcon,
    TableChartIcon, InfoIcon, SettingsIcon, CodeIcon, SunIcon, MoonIcon
} from '../../svg/Icons';
import browserStyle from './SQLLab.module.scss';
import mobileStyle from './MobileSQLLab.module.scss';
import 'react-chart-editor/lib/react-chart-editor.css';
import 'react-table/react-table.css'
import { connect } from 'react-redux';
import QueryPlan from "./queryplan-viewer/QueryPlan";
import { isMobile, isTablet } from 'react-device-detect';
import KeyboardEventHandler from 'react-keyboard-event-handler';

let s = (isMobile) ? mobileStyle : browserStyle;

interface IMobileTabProps {
    activeMobileTab: number;
    onTabClick: (tabID: number) => void;
    mobileTabID: number;
}

function MobileTab(props: IMobileTabProps) {
    const isActive = props.mobileTabID === props.activeMobileTab;
    let icon = <div />;
    switch (props.mobileTabID) {
        case 0:
            icon = <SettingsIcon width="24px" height="24px" />;
            break;
        case 1:
            icon = <CodeIcon width="24px" height="24px" />;
            break;
        case 2:
            icon = <TableChartIcon width="24px" height="24px" />;
            break;
        case 3:
            icon = <PlanIcon width="24px" height="24px" />;
            break;
    }
    return (
        <div className={classNames([s.tab], isActive ? [s.tabActive] : "")}
            onClick={() => props.onTabClick(props.mobileTabID)} >
            {icon}
        </div>
    );
}

interface IInspectorTabProps {
    labView: number;
    onTabClick: (tabID: number) => void;
    tabID: number;
}

function InspectorTab(props: IInspectorTabProps) {
    const isActive = props.tabID === props.labView;
    let icon = <div />;
    switch (props.tabID) {
        case 0:
            icon = <TableChartIcon width="24px" height="24px" />;
            break;
        case 1:
            icon = <PlanIcon width="24px" height="24px" />;
            break;
        case 2:
            icon = <InsertChartIcon width="24px" height="24px" />;
            break;
    }
    return (
        <div
            className={classNames(
                [s.tab],
                isActive ? [s.tabActive] : ""
            )}
            onClick={() => props.onTabClick(props.tabID)}
        >
            {icon}
        </div>
    );
}

interface IInspectorTabContentProps {
    labView: number;
    queryResult: Store.QueryResult | null;
    queryError: string | null;
    queryPlan: Store.PlanResult | null;
}

function InspectorTabContent(props: IInspectorTabContentProps): JSX.Element {
    let title = "";
    switch (props.labView) {
        case 0:
            title = "Query Results";
            break;
        case 1:
            title = "Query Plan";
            break;
        case 2:
            title = "Chart Editor"
            break;
    }

    let body: JSX.Element | null = null;
    switch (props.labView) {
        case 0:
            if (props.queryError != null) {
                body = (
                    <div className={s.errorCat}>
                        <img className={s.logo} src="error_cat.svg" alt="Error Cat"
                            title="Taken from freepik - de.freepik.com" />
                        <div className={s.text}>
                            {props.queryError}
                        </div>
                    </div>
                );
            } else if (props.queryResult == null) {
                body = (
                    <div className={s.giveSQL}>
                        <img className={s.logo} src="give_sql.svg" alt="Error Cat" title="Taken from freepik - de.freepik.com" />
                        <div className={s.text}>
                            <span>Use</span>
                            <div className={s.play}>
                                <PlayIcon width="24px" height="24px" fill="rgb(100, 100, 100)" />
                            </div>
                            <span>to run a query!</span></div>
                    </div>

                );
            } else {
                // The sent results are server-side limited to 1k tuples, but we still report an accurate
                // resultCount. Also limit the displayed rows here to avoid gobbling up browser memory
                const rowIDs: number[] = Array.from(Array(Math.min(props.queryResult.resultCount, 1000)).keys());
                const columns = [];
                for (let colID = 0; colID < props.queryResult.columns.length; ++colID) {
                    columns.push({
                        Header: props.queryResult.columns[colID].columnName,
                        accessor: (rowID: number) => String(props.queryResult!.columns[colID].data[rowID]),
                        id: String(colID),
                        minWidth: 150
                    })
                }
                body = (
                    <ReactTable
                        className={s.dataTable}
                        data={rowIDs}
                        columns={columns}
                        showPaginationBottom={true}
                        showPageSizeOptions={true}
                        defaultPageSize={isTablet ? 20 : (isMobile ? 10 : 25)}
                        pageSizeOptions={[5, 10, 20, 25, 100]}
                        sortable={true}
                        multiSort={true}
                        resizable={false}
                    />
                );
            }
            break;
        case 1:
            if (props.queryPlan == null && props.queryError != null) {
                body = (
                    <div className={s.errorCat}>
                        <img className={s.logo} src="error_cat.svg" alt="Error Cat"
                            title="Taken from freepik - de.freepik.com" />
                        <div className={s.text}>
                            {props.queryError}
                        </div>
                    </div>
                );
            } else if (props.queryPlan == null && props.queryResult == null) {
                body = (
                    <div className={s.giveSQL}>
                        <img className={s.logo} src="give_sql.svg" alt="Error Cat" title="Taken from freepik - de.freepik.com" />
                        <div className={s.text}>
                            <span>Use</span>
                            <div className={s.play}>
                                <PlayIcon width="24px" height="24px" fill="rgb(100, 100, 100)" />
                            </div>
                            <span>to run a query!</span></div>
                    </div>

                );
            } else {
                body = <QueryPlan />;
            }
            break;
        case 2:
            body = <ChartEditorLoader />;
            break;
    }

    return (
        <div className={s.content}>
            <div className={s.header}>
                <div className={s.title}>
                    {title}
                </div>
            </div>
            <div className={s.body}>
                {body || <div />}
            </div>
        </div>
    );
}

interface ISQLLabProps {
    appContext: IAppContext;
    labView: number;
    navigateLab: (tabID: number) => void;
    queryDuration: number | null;
    queryResult: Store.QueryResult | null;
    queryError: string | null;
    queryPlan: Store.PlanResult | null;
    queryStart: number | null;
    queryDefinitions: QueryDefinitions;
    querySets: Store.QuerySet[];
    info: string | undefined;
    updateQueryText: (value: string) => void;
    updateTheme: (value: string) => void;
    updateQueryInfo: (schemaId: string, scale: number | undefined, skew: number | undefined) => void;
}

interface ISQLLabState {
    querySelectorExpanded: boolean;
    schemaSelectorExpanded: boolean;
    scaleSelectorExpanded: boolean;
    skewSelectorExpanded: boolean;
    querySelectorScrollState: number;
    schemaSelectorScrollState: number;
    scaleSelectorScrollState: number;
    skewSelectorScrollState: number;
    schemaId: string;
    queryIndex: number;
    scale: number | undefined;
    skew: number | undefined;
    mobileTabId: number;
    theme: string;
}

class SQLLab extends React.Component<ISQLLabProps, ISQLLabState> {
    private querySelector: React.RefObject<HTMLDivElement> = React.createRef();
    private schemaSelector: React.RefObject<HTMLDivElement> = React.createRef();
    private scaleSelector: React.RefObject<HTMLDivElement> = React.createRef();
    private skewSelector: React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props: ISQLLabProps) {
        super(props);
        this.state = {
            querySelectorExpanded: false,
            schemaSelectorExpanded: false,
            scaleSelectorExpanded: false,
            skewSelectorExpanded: false,
            querySelectorScrollState: 0,
            schemaSelectorScrollState: 0,
            scaleSelectorScrollState: 0,
            skewSelectorScrollState: 0,
            schemaId: 'pizza',
            queryIndex: 0,
            scale: 1,
            skew: 0,
            mobileTabId: 1,
            theme: "dark"
        };
    }

    public disableSelectors() {
        this.setState({
            ...this.state,
            querySelectorExpanded: false,
            schemaSelectorExpanded: false,
            scaleSelectorExpanded: false,
            skewSelectorExpanded: false,
        });
    }

    public toggleQuerySelector() {
        this.setState({
            ...this.state,
            querySelectorExpanded: !this.state.querySelectorExpanded,
            schemaSelectorExpanded: false,
            scaleSelectorExpanded: false,
            skewSelectorExpanded: false,
        });
    }

    public toggleSchemaSelector() {
        this.setState({
            ...this.state,
            querySelectorExpanded: false,
            schemaSelectorExpanded: !this.state.schemaSelectorExpanded,
            scaleSelectorExpanded: false,
            skewSelectorExpanded: false,
        });
    }

    public toggleScaleSelector() {
        this.setState({
            ...this.state,
            querySelectorExpanded: false,
            schemaSelectorExpanded: false,
            scaleSelectorExpanded: !this.state.scaleSelectorExpanded,
            skewSelectorExpanded: false,
        });
    }

    public toggleSkewSelector() {
        this.setState({
            ...this.state,
            querySelectorExpanded: false,
            schemaSelectorExpanded: false,
            scaleSelectorExpanded: false,
            skewSelectorExpanded: !this.state.skewSelectorExpanded
        });
    }

    public saveQuerySelectorScrollState(e: React.UIEvent) {
        this.setState({ ...this.state, querySelectorScrollState: e.currentTarget.scrollTop });
    }

    public saveSchemaSelectorScrollState(e: React.UIEvent) {
        this.setState({ ...this.state, schemaSelectorScrollState: e.currentTarget.scrollTop });
    }

    public saveScaleSelectorScrollState(e: React.UIEvent) {
        this.setState({ ...this.state, scaleSelectorScrollState: e.currentTarget.scrollTop });
    }

    public saveSkewSelectorScrollState(e: React.UIEvent) {
        this.setState({ ...this.state, skewSelectorScrollState: e.currentTarget.scrollTop });
    }

    public getQueryDefinition(schemaId = this.state.schemaId): Query[] {
        return this.props.queryDefinitions[schemaId].queries;
    }

    public getSchemaName(schemaId = this.state.schemaId): string {
        return this.props.queryDefinitions[schemaId].name;
    }

    public getQuerySet(schemaId = this.state.schemaId): QuerySet | undefined {
        return this.props.querySets.find(querySet => querySet.id === schemaId)!
    }

    public async loadQuery(query: Store.Query, index: number) {
        this.props.updateQueryText(query.text.join('\n'));
        this.setState({ ...this.state, querySelectorExpanded: false, queryIndex: index });
        await this.props.appContext.controller.startStopQuery()
    }

    public async loadNextQuery(key: string) {
        const queries = this.getQueryDefinition(this.getQuerySet()!.id);
        let newIndex = this.state.queryIndex + (key === "alt+right" ? 1 : -1);
        if (newIndex < 0) newIndex += queries.length;
        if (newIndex >= queries.length) newIndex -= queries.length;
        this.loadQuery(queries[newIndex], newIndex);
    }

    public async switchSchema(schemaId: string) {
        const queries = this.getQueryDefinition(schemaId);
        const scales = this.getQuerySet(schemaId)?.scales;
        const skews = this.getQuerySet(schemaId)?.skews;
        const scale = (scales && scales.length > 0) ? scales[0] : undefined
        const skew = (scales && scales.length > 0 && skews && skews.length > 0) ? skews[0][0] : undefined
        this.props.updateQueryText((queries.length > 0) ? queries[0].text.join('\n') : '')
        this.props.updateQueryInfo(schemaId, scale, skew);
        this.setState({
            ...this.state,
            schemaSelectorExpanded: false,
            schemaId: schemaId,
            queryIndex: 0,
            scale: scale,
            skew: skew
        });
        await this.props.appContext.controller.startStopQuery()
    }

    public toggleTheme() {
        const newTheme = (this.state.theme === "dark") ? "light" : "dark";
        this.props.updateTheme(newTheme);
        this.setState({
            ...this.state,
            querySelectorExpanded: false,
            schemaSelectorExpanded: false,
            scaleSelectorExpanded: false,
            skewSelectorExpanded: false,
            theme: newTheme
        });

    }

    public async changeScaleFactor(scale: number) {
        const skews = (this.getQuerySet()?.skews && this.getQuerySet()!.skews!.length > 0) ? this.getQuerySet()!.skews![this.getQuerySet()!.scales!.findIndex(s => s === scale)] : [];
        const skew = (skews.length === 0 || this.state.skew === undefined) ? undefined : ((skews.includes(this.state.skew)) ? this.state.skew : skews[0])
        this.props.updateQueryInfo(this.state.schemaId, scale, skew);
        this.setState({
            ...this.state, scaleSelectorExpanded: false,
            scale: scale,
            skew: skew
        })
        await this.props.appContext.controller.startStopQuery()
    }

    public async changeSkew(skew: number) {
        this.props.updateQueryInfo(this.state.schemaId, this.state.scale, skew);
        this.setState({ ...this.state, skewSelectorExpanded: false, skew: skew });
        await this.props.appContext.controller.startStopQuery()
    }

    public async changeMobileTabId(mobileTabID: number) {
        this.setState({ ...this.state, mobileTabId: mobileTabID });
        if (mobileTabID > 1) {
            this.props.navigateLab(mobileTabID - 2);
        }
    }

    public componentDidUpdate(prevProps: Readonly<ISQLLabProps>, prevState: Readonly<ISQLLabState>, snapshot?: any) {
        if (this.state.querySelectorExpanded && this.querySelector.current) {
            this.querySelector.current.scrollTop = this.state.querySelectorScrollState;
        }
        if (this.state.schemaSelectorExpanded && this.schemaSelector.current) {
            this.schemaSelector.current.scrollTop = this.state.schemaSelectorScrollState;
        }
        if (this.state.scaleSelectorExpanded && this.scaleSelector.current) {
            this.scaleSelector.current.scrollTop = this.state.scaleSelectorScrollState;
        }
        if (this.state.skewSelectorExpanded && this.skewSelector.current) {
            this.skewSelector.current.scrollTop = this.state.skewSelectorScrollState;
        }
    }

    public render() {
        const queryRunning = this.props.queryStart != null;
        const theme = this.state.theme
        let executionTime = '-';
        let compilationTime = '-';
        let columns = '-';
        let rows = '-';
        if (this.props.queryResult) {
            executionTime = formatDurationMS(this.props.queryResult.executionTime * 1000);
            compilationTime = formatDurationMS(this.props.queryResult.compilationTime * 1000);
            columns = String(this.props.queryResult.columns.length);
            rows = String(this.props.queryResult.resultCount);
        }

        if (isMobile) {
            return (
                <div className={s.SQLLab}>
                    {this.state.mobileTabId === 1 &&
                        <div className={s.editor}>
                            <SQLEditorLoader />
                        </div>
                    }
                    {this.state.mobileTabId > 1 &&
                        <div className={s.results}>
                            <InspectorTabContent labView={this.props.labView} queryResult={this.props.queryResult}
                                queryError={this.props.queryError} queryPlan={this.props.queryPlan} />
                        </div>
                    }
                    <div className={s.controls}>
                        <div />
                        <MobileTab mobileTabID={0} activeMobileTab={this.state.mobileTabId} onTabClick={(id: number) => this.changeMobileTabId(id)} />
                        <MobileTab mobileTabID={1} activeMobileTab={this.state.mobileTabId} onTabClick={(id: number) => this.changeMobileTabId(id)} />
                        <div />
                        <MobileTab mobileTabID={2} activeMobileTab={this.state.mobileTabId} onTabClick={(id: number) => this.changeMobileTabId(id)} />
                        <MobileTab mobileTabID={3} activeMobileTab={this.state.mobileTabId} onTabClick={(id: number) => this.changeMobileTabId(id)} />
                        <div className={s.themebutton} onClick={() => { this.toggleTheme(); }}>
                            {
                                theme === "dark"
                                    ? <SunIcon width="24px" height="24px" fill="rgb(255, 255, 255)" />
                                    : <MoonIcon width="24px" height="24px" fill="rgb(255, 255, 255)" />
                            }
                        </div>
                        <div className={s.runbutton} onClick={() => {
                            if (this.state.mobileTabId <= 1)
                                this.changeMobileTabId(2);
                            this.props.appContext.controller.startStopQuery()
                        }}>
                            {
                                queryRunning
                                    ? <StopIcon width="24px" height="24px" fill="rgb(255, 255, 255)" />
                                    : <PlayIcon width="24px" height="24px" fill="rgb(255, 255, 255)" />
                            }
                        </div>
                    </div>
                </div >)
        }

        return (
            <div className={s.SQLLab} >
                <KeyboardEventHandler
                    handleKeys={['alt+left', 'alt+right']}
                    onKeyEvent={(key, e) => this.loadNextQuery(key)} />
                <KeyboardEventHandler
                    handleKeys={['alt+left', 'alt+right']}
                    onKeyEvent={(key, e) => this.loadNextQuery(key)}>
                    <SplitPane split="horizontal">
                        <div className={s.queryPrompt}>
                            <div className={s.controls}>
                                <div className={s.runbutton} onClick={() => { this.disableSelectors(); this.props.appContext.controller.startStopQuery(); }}>
                                    {
                                        queryRunning
                                            ? <StopIcon width="24px" height="24px" fill="rgb(255, 255, 255)" />
                                            : <PlayIcon width="24px" height="24px" fill="rgb(255, 255, 255)" />
                                    }
                                </div>
                                <div className={s.themebutton} onClick={() => { this.toggleTheme(); }}>
                                    {
                                        theme === "dark"
                                            ? <SunIcon width="24px" height="24px" fill="rgb(255, 255, 255)" />
                                            : <MoonIcon width="24px" height="24px" fill="rgb(255, 255, 255)" />
                                    }
                                </div>
                                {this.props.info &&
                                    <div className={s.infobutton} data-tip={this.props.info}                                >
                                        <InfoIcon width="24px" height="24px" fill="rgb(255, 255, 255)" />
                                        <ReactTooltip className={s.tooltip} place="right" type="light" effect="solid" multiline={true} />
                                    </div>
                                }
                            </div>
                            <div className={s.editor} onClick={() => this.disableSelectors()}>
                                <SQLEditorLoader />
                            </div>
                        </div>
                        <div className={s.results} onClick={() => this.disableSelectors()}>
                            <div className={s.inspector}>
                                <div className={s.sidebar}>
                                    <InspectorTab tabID={0} labView={this.props.labView}
                                        onTabClick={this.props.navigateLab} />
                                    <InspectorTab tabID={1} labView={this.props.labView}
                                        onTabClick={this.props.navigateLab} />
                                    <InspectorTab tabID={2} labView={this.props.labView}
                                        onTabClick={this.props.navigateLab} />
                                </div>
                                <InspectorTabContent labView={this.props.labView} queryResult={this.props.queryResult}
                                    queryError={this.props.queryError} queryPlan={this.props.queryPlan} />
                            </div>
                            <div className={s.queryStats}>
                                <div className={s.header}>
                                    <div className={s.title}>
                                        Query Stats
                                    </div>
                                </div>
                                <div className={s.table}>
                                    <div className={s.label}>
                                        &#916; t <sub>compilation</sub>
                                    </div>
                                    <div className={s.value}>
                                        {compilationTime}
                                    </div>
                                    <div className={s.label}>
                                        &#916; t <sub>execution</sub>
                                    </div>
                                    <div className={s.value}>
                                        {executionTime}
                                    </div>
                                    <div className={s.label}>
                                        Columns
                                    </div>
                                    <div className={s.value}>
                                        {columns}
                                    </div>
                                    <div className={s.label}>
                                        Rows
                                    </div>
                                    <div className={s.value}>
                                        {rows}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SplitPane>
                </KeyboardEventHandler>
            </div >
        );
    }
}

function mapStateToSQLLabProps(state: Store.RootState) {
    const serverConfig = (state.selectedServer && state.serverConfigs.get(state.selectedServer)) || null;
    return {
        labView: state.labView,
        queryDuration: state.labQueryDuration,
        queryResult: state.labQueryResult,
        queryError: state.labQueryError,
        queryPlan: state.labPlanResult,
        queryStart: state.labQueryStart,
        info: serverConfig?.info,
        querySets: serverConfig ? serverConfig.querySets : [],
        queryDefinitions: serverConfig ? state.appConfig!.queryDefinitions! : {}
    };
}

function mapDispatchToSQLLabProps(dispatch: Store.Dispatch) {
    return {
        updateQueryText: (value: string) => dispatch(Store.updateQueryText(value)),
        updateTheme: (value: string) => dispatch(Store.updateTheme(value)),
        updateQueryInfo: (schemaId: string, scale: number | undefined, skew: number | undefined) => dispatch(Store.updateQueryInfo(schemaId, scale, skew)),
        navigateLab: (tabID: number) => dispatch(Store.navigateLab(tabID))
    };
}

export default withAppContext(connect(mapStateToSQLLabProps, mapDispatchToSQLLabProps)(SQLLab));

